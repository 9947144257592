<template>
  <b-card>
    <b-row>
      <b-col>
        <h4>Editando entrada de cliente</h4>
        <h6>Centro de consumo: <b>{{ consumptionCenterName }}</b></h6>
      </b-col>
      <b-col class="text-right">
        <b-button
          :to="{ name: 'waiting-list'}"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="warning"
        >
          <feather-icon icon="ArrowLeftIcon" style="color: white" />
        </b-button>
      </b-col>
    </b-row>

    <div v-if="!!guest">
      <b-overlay :show="isLoadingCustomer || isUpdatingCustomer">
        <ValidationObserver ref="editWaitingCustomer" v-slot="{ invalid }">
          <GuestForm
            :guest="guest"
            :invalid="invalid"
            :isSavingCustomer="isUpdatingCustomer"
            :isEdition="true"
            @save-changes="save"
          />
        </ValidationObserver>
      </b-overlay>
    </div>
    <div v-else-if="isLoadingCustomer || isUpdatingCustomer" class="text-center">
      <b-spinner/> <br> Cargando información del cliente
    </div>
    <div v-else>
      <b-alert show variant="warning" class="p-1">No se ha encontrado este cliente</b-alert>
    </div>
  </b-card>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { showAlertMessage } from '@/helpers/helpers'
import GuestForm from '@/modules/guestPager/components/GuestForm'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  async created(){
    await this.init()
  },
  components: {
    GuestForm,
  },
  data() {
    return {
      idHolderCustomer: this.$route.params.id,
      isLoadingCustomer:false,
      isUpdatingCustomer:false,
      consumptionCenterName: null,

      guest: null,
    }
  },
  computed:{
    ...mapState('start', ['languages', 'hotels']),
    ...mapState('guestPager', ['countryCodes']),
  },
  methods: {
    ...mapActions('guestPager', ['fetchWaitingList', 'updateGuestOnHold', 'getInitialContentConsuptionsCenters']),
    async init(){
      if (!isNaN(this.idHolderCustomer)){
        this.isLoadingCustomer = true
        await this.getInitialContentConsuptionsCenters({
          hotels: !this.hotels.length,
          languages: !this.languages.length,
          countryCodes: !this.countryCodes.length,
        })
        const customer = await this.fetchWaitingList({id: this.idHolderCustomer})
        if (customer) {
          this.consumptionCenterName = customer.consumptionCenterName
          const ladaCode = this.countryCodes.find(lada => lada.code == customer?.lada)
          this.guest = {
            id: customer?.id,
            name: customer?.guestName,
            lastname: customer?.guestLastname,
            room: customer?.room,
            pax: customer?.pax,
            phone: customer?.phone,
            lada: ladaCode,
            notes: customer?.notes,
            language: customer?.language
          }
        }
        this.isLoadingCustomer = false
      }
    },
    async save(payload){
      this.isUpdatingCustomer = true
      const data = {
        id: payload.id,
        guestName: payload.name,
        guestLastname: payload.lastname,
        consumptionCenter: payload.consumptionCenter,
        pax: payload.pax,
        room: payload.room,
        phone: payload.phone,
        lada: payload.lada?.code || null,
        notes: payload.notes,
        language: payload.language
      }
      const response = await this.updateGuestOnHold(data)
      if (response) {
        this.$router.push({name: 'waiting-list'})
        this.isUpdatingCustomer = false
        showAlertMessage('Ok', 'InfoIcon', 'Registro modificado', 'success', 4000)
      }
      this.isUpdatingCustomer = false
    }
  }
}
</script>

<style>

</style>